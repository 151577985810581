import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { useTranslation } from "next-i18next";
import CmsImage from "../cms/cmsImage/cmsImage";
import { ScrollToTopStyle } from "./scrollToTopStyle";

export default function ScrollToTop() {
  const { t: tPublic } = useTranslation("public");
  const scrollPosition = useAppSelector(state => state.general.scroll.position);

  function scroll() {
    document.getElementById("scroll-to-top-anchor")?.scrollIntoView();
    const defaultScrollbehavior: ScrollBehavior = "smooth";
    setTimeout(() => {
      document
        .getElementById("layout")
        ?.setAttribute("data-tempdisableanimations", "true");
      window.scrollTo({
        behavior:
          (globalConfig?.scrolltotop?.scrollEffect as ScrollBehavior) ||
          defaultScrollbehavior,
        top: 0,
      });
    }, 100);
  }

  return (
    <>
      {globalConfig?.scrolltotop.enabled && (
        <>
          <div
            className={`${
              scrollPosition &&
              typeof scrollPosition === "number" &&
              scrollPosition > 0
                ? ""
                : "hidden"
            } scroll-to-top-wrapper d-print-none`}
            onClick={scroll}
          >
            {globalConfig.scrolltotop.icon ? (
              <CmsImage
                image={globalConfig.scrolltotop.icon}
                alt={tPublic("scrollTop-altText")}
                title={tPublic("scrollTop-title")}
                height="100%"
                objectFit="contain"
                width={"100%"}
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                width="100%"
                height="100%"
                style={{ verticalAlign: "unset" }}
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M0 0H32V32H0z"
                    transform="translate(-1028 -172) translate(832 140) translate(32 32) translate(164) matrix(1 0 0 -1 0 32)"
                  ></path>
                  <path
                    fill={storeColorCss(
                      globalConfig.scrolltotop.arrowColor,
                      "var(--pb-black)"
                    )}
                    fillRule="nonzero"
                    d="M11.384 13.333h9.232c.638 0 .958.68.505 1.079l-4.613 4.07c-.28.246-.736.246-1.016 0l-4.613-4.07c-.453-.399-.133-1.079.505-1.079z"
                    transform="translate(-1028 -172) translate(832 140) translate(32 32) translate(164) matrix(1 0 0 -1 0 32)"
                  ></path>
                </g>
              </svg>
            )}
          </div>
          <ScrollToTopStyle />
        </>
      )}
    </>
  );
}
