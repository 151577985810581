import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointDesktopPx, lowerBreakpointTabletPx, lowerBreakpointWqhdPx } from "@/utils/util";

export const ScrollToTopStyle = () => {
  const scrolltotopWidth = {
    mobile: globalConfig?.scrolltotop.scrolltotopWidth?.mobile ?? 40,
    tablet: globalConfig?.scrolltotop.scrolltotopWidth?.tablet ?? 50,
    desktop: globalConfig?.scrolltotop.scrolltotopWidth?.desktop ?? 60,
    wqhd: globalConfig?.scrolltotop.scrolltotopWidth?.wqhd ?? 70,
  };

  const scrolltotopHeight = {
    mobile: globalConfig?.scrolltotop.scrolltotopHeight?.mobile ?? 40,
    tablet: globalConfig?.scrolltotop.scrolltotopHeight?.tablet ?? 50,
    desktop: globalConfig?.scrolltotop.scrolltotopHeight?.desktop ?? 60,
    wqhd: globalConfig?.scrolltotop.scrolltotopHeight?.wqhd ?? 70,
  };
  return (
    <style jsx global>{`
      .scroll-to-top-wrapper {
        display: block;
        position: fixed;
        bottom: 30px;
        right: 50px;
        z-index: 1000;
        width: ${scrolltotopWidth.mobile}px;
        height: ${scrolltotopHeight.mobile}px;
        font-size: 18px;
        background-color: ${storeColorCss(
          globalConfig?.scrolltotop.bgColor,
          "transparent"
        )};
        padding: 0px;
        border-radius: ${globalConfig?.scrolltotop.borderRadius.mobile}px;
        border: 1px solid ${globalConfig?.scrolltotop.bgColor};
        &.hidden {
          display: none;
        }
        :hover {
          cursor: pointer;
        }
      }

      /* Container Tablet */
      @media (min-width: ${lowerBreakpointTabletPx}) {
        .scroll-to-top-wrapper {
          width: ${scrolltotopWidth.tablet}px;
          height: ${scrolltotopHeight.tablet}px;
          padding: 3px;
          border-radius: ${globalConfig?.scrolltotop.borderRadius.tablet}px;
        }
      }

      /* Container Desktop */
      @media (min-width: ${lowerBreakpointDesktopPx}) {
        .scroll-to-top-wrapper {
          width: ${scrolltotopWidth.desktop}px;
          height: ${scrolltotopHeight.desktop}px;
          padding: 6px;
          border-radius: ${globalConfig?.scrolltotop.borderRadius.desktop}px;
        }
      }

      /* Container 2k Desktop */
      @media (min-width: ${lowerBreakpointWqhdPx}) {
        .scroll-to-top-wrapper {
          width: ${scrolltotopWidth.wqhd}px;
          height: ${scrolltotopHeight.wqhd}px;
          padding: 8px;
          border-radius: ${globalConfig?.scrolltotop.borderRadius.wqhd}px;
        }
      }
    `}</style>
  );
};
